<template>
    <v-app>
        <v-main>
            <v-container>
                <v-row justify="center">
                    <v-col cols="4" class="text-center">
                        <v-form @submit.prevent="login">
                            <v-card style="position:relative;">
                                <v-overlay :value="loading" absolute>
                                    <v-progress-circular
                                        indeterminate
                                        size="64"
                                    ></v-progress-circular>
                                </v-overlay>
                                <v-card-title class="d-flex justify-center">
                                    <v-img
                                        :src="require('@/assets/logo.png')"
                                        width="400px"
                                        max-width="400px"
                                        contain
                                    ></v-img>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="10" class="text-center ma-auto">
                                            <v-text-field prepend-icon="mdi-email" label="E-mailadres" v-model="email"></v-text-field>
                                            <v-text-field type="password" prepend-icon="mdi-lock" label="Wachtwoord" v-model="password"></v-text-field>
                                        </v-col>

                                    </v-row>

                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" type="submit">Login</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                        <v-btn text color="secondary">Wachtwoord vergeten</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "Login",
    data () {
        return {
            email: '',
            password: '',
            loading: false
        }
    },
    methods: {
        async login() {
            this.loading = true;

            try {
                await this.$store.dispatch('auth/login', {
                    email: this.email,
                    password: this.password
                });
                await this.$router.push({name: 'Home'});
            } catch(e) {
                if (e.response.status === 422) {
                    if (e.response.data.error) {
                        await this.$store.dispatch('message/showMessage', {
                            text: e.response.data.error,
                            color: 'error'
                        });
                    }
                }
            }

            finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
